html {
  background-color: #181818;
}



/* NAVBAR */
.navbar-container {
  background-color: white;
  font-size: 1.2em;
  font-family: 'Josefin Slab', serif;
}

.navbar-title {
  font-family: 'Fontdiner Swanky', cursive;
  color: #181818 !important;
}

.navbar-brand {
  color: #181818;
}

.nav-link {
  color: #181818 !important;
}

.nav-link:hover {
  color: tomato;
}

.navbar-toggler .line {
  background-color: #181818;
}

.navbar-title {
  color: red;
}


/* BANNER */
.banner-container {
  border-top: solid 3px #122a50;
  border-bottom: solid 3px #122a50;
  box-shadow: inset 0 0 20px #122a50, 0 0 20px #122a50;
  background-color: #66b2e0;
  height: 70vh;
  width: 100%;
  /* overflow: hidden; */
  background-image: url("header_background.png");
  background-position: left 100%;
}

/* .banner-background {
  width: 100%;
  max-height: 70vh;
  position: absolute;
  border: solid red;
  overflow: hidden;
}

.banner-background-image {
  height: auto;
  width: 100%;
} */

.banner-banner {
  height: 70vh;
  display: flex;
  position: relative;
}

.banner-banner img:nth-child(1) {
  height: 100%;
  position: absolute;
  bottom: 0px;
  left: 70px;
}

.banner-banner img:nth-child(2) {
  position: absolute;
  bottom: -180px;
  left: 0;
}

.banner-banner img:nth-child(3) {
  height: 100%;
  position: absolute;
  bottom: 0px;
  right: 70px;
}


@media (max-width: 1400px) {

  /* BANNER */
  .banner-container {
    height: 60vh;
    width: 100%;
    /* overflow: hidden; */
  }

  .banner-banner {
    height: 60vh;
    display: flex;
    position: relative;
  }

  .banner-banner img:nth-child(1) {
    height: 100%;
    position: absolute;
    bottom: 0px;
    left: 70px;
  }

  .banner-banner img:nth-child(2) {
    position: absolute;
    bottom: -180px;
    left: 0;
    height: 400px;
  }

  .banner-banner img:nth-child(3) {
    height: 100%;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    right: 70px;
  }
}

@media (max-width: 1200px) {

  /* BANNER */
  .banner-container {
    height: 50vh;
    width: 100%;
    /* overflow: hidden; */
  }

  .banner-banner {
    height: 50vh;
    display: flex;
    position: relative;
  }

  .banner-banner img:nth-child(1) {
    height: 100%;
    position: absolute;
    bottom: 0px;
    left: 70px;
  }

  .banner-banner img:nth-child(2) {
    position: absolute;
    bottom: -150px;
    left: 0;
    height: 350px;
  }

  .banner-banner img:nth-child(3) {
    height: 100%;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    right: 70px;
  }
}

@media (max-width: 1050px) {

  /* BANNER */
  .banner-container {
    height: 40vh;
    width: 100%;
    /* overflow: hidden; */
  }

  .banner-banner {
    height: 40vh;
    display: flex;
    position: relative;
  }

  .banner-banner img:nth-child(1) {
    height: 100%;
    position: absolute;
    bottom: 0px;
    left: 70px;
  }

  .banner-banner img:nth-child(2) {
    position: absolute;
    bottom: -150px;
    left: 0;
    height: 300px;
  }

  .banner-banner img:nth-child(3) {
    height: 100%;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    right: 70px;
  }
}

@media (max-width: 850px) {

  /* BANNER */
  .banner-container {
    height: 30vh;
    width: 100%;
    /* overflow: hidden; */
  }

  .banner-banner {
    height: 30vh;
    display: flex;
    position: relative;
  }

  .banner-banner img:nth-child(1) {
    height: 100%;
    position: absolute;
    bottom: 0px;
    left: 70px;
  }

  .banner-banner img:nth-child(2) {
    position: absolute;
    bottom: -120px;
    left: 0;
    height: 250px;
  }

  .banner-banner img:nth-child(3) {
    height: 100%;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    right: 70px;
  }
}

@media (max-width: 700px) {

  /* BANNER */
  .banner-container {
    height: 25vh;
    width: 100%;
    /* overflow: hidden; */
  }

  .banner-banner {
    height: 25vh;
    display: flex;
    position: relative;
  }

  .banner-banner img:nth-child(1) {
    height: 100%;
    position: absolute;
    bottom: 0px;
    left: 70px;
  }

  .banner-banner img:nth-child(2) {
    position: absolute;
    bottom: -100px;
    left: 0;
    height: 200px;
  }

  .banner-banner img:nth-child(3) {
    height: 100%;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    right: 70px;
  }
}

@media (max-width: 600px) {

  /* BANNER */
  .banner-container {
    height: 20vh;
    width: 100%;
    /* overflow: hidden; */
  }

  .banner-banner {
    height: 20vh;
    display: flex;
    position: relative;
  }

  .banner-banner img:nth-child(1) {
    height: 100%;
    position: absolute;
    bottom: 0px;
    left: 70px;
  }

  .banner-banner img:nth-child(2) {
    position: absolute;
    bottom: -80px;
    left: 0;
    height: 150px;
  }

  .banner-banner img:nth-child(3) {
    height: 100%;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    right: 70px;
  }
}

@media (max-width: 500px) {

  /* BANNER */
  .banner-container {
    height: 20vh;
    width: 100%;
    /* overflow: hidden; */
  }

  .banner-banner {
    height: 20vh;
    display: flex;
    position: relative;
  }

  .banner-banner img:nth-child(1) {
    height: 100%;
    position: absolute;
    bottom: 0px;
    left: 30px;
  }

  .banner-banner img:nth-child(2) {
    position: absolute;
    bottom: -80px;
    left: 0;
    height: 150px;
  }

  .banner-banner img:nth-child(3) {
    height: 100%;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    right: 30px;
  }
}

@media (max-width: 420px) {

  /* BANNER */
  .banner-container {
    height: 20vh;
    width: 100%;
    /* overflow: hidden; */
  }

  .banner-banner {
    height: 20vh;
    display: flex;
    position: relative;
  }

  .banner-banner img:nth-child(1) {
    height: 100%;
    position: absolute;
    bottom: 0px;
    left: 20px;
  }

  .banner-banner img:nth-child(2) {
    position: absolute;
    bottom: -80px;
    left: 0;
    height: 150px;
  }

  .banner-banner img:nth-child(3) {
    height: 100%;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    right: 20px;
  }
}

@media (max-width: 390px) {

  /* BANNER */
  .banner-container {
    height: 20vh;
    width: 100%;
    /* overflow: hidden; */
  }

  .banner-banner {
    height: 20vh;
    display: flex;
    position: relative;
  }

  .banner-banner img:nth-child(1) {
    height: 100%;
    position: absolute;
    bottom: 0px;
    left: 5px;
  }

  .banner-banner img:nth-child(2) {
    position: absolute;
    bottom: -80px;
    left: 0;
    height: 150px;
  }

  .banner-banner img:nth-child(3) {
    height: 100%;
    z-index: 5;
    position: absolute;
    bottom: 0px;
    right: 5px;
  }
}


/* SOCIAL */
.social-container {
  background-color: white;
  color: #181818;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
}

.social-locations-container {
  display: flex;
  flex-flow: row;
}

.social-locations {
  margin-right: 15px;
  font-family: 'Josefin Slab', serif;
}

.social-locations-container h1 {
  font-size: 1.6em;
}

.social-icons {
  display: flex;
}

.social-icons img {
  margin-right: 10px;
  width: 80px;
}


@media (max-width: 1360px) {
  .social-locations-container {
    display: flex;
    flex-flow: column;
  }
}

@media (max-width: 900px) {
  .social-locations-container h1 {
    font-size: 1.4em;
  }

  .social-icons img {
    width: 60px;
  }
}

@media (max-width: 750px) {
  .social-locations-container h1 {
    font-size: 1.2em;
  }

  .social-icons img {
    width: 40px;
  }
}

@media (max-width: 650px) {
  .social-locations-container h1 {
    font-size: 1em;
  }

  .social-icons img {
    width: 40px;
  }
}

@media (max-width: 550px) {
  .social-container {
    justify-content: flex-start;
  }

  .social-locations-container {
    display: flex;
    flex-flow: column;
    margin-top: 80px;
  }

  .social-locations-container h1 {
    font-size: 1.2em;
  }

  .social-icons img {
    width: 40px;
  }
}

/* WELCOME */
.welcome-container {
  height: 100%;
  width: 100%;
  background-color: white;
  color: #181818;
  display: flex;
}

.welcome-message {
  width: 80vw;
  padding: 30px 120px;
}

.welcome-message h1 {
  font-family: 'Fontdiner Swanky', cursive;
}

.welcome-message p {
  font-family: 'Josefin Slab', serif;
  font-size: 1.1em;
  margin-top: 20px;
}

.welcome-social {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
}

.welcome-social img {
  margin-bottom: 80px;
}

.welcome-social iframe {
  height: 700px;
}

.welcome-social img.facebook-logo {
  margin: 0;
  padding: 0;
}

@media (max-width: 1250px) {
  .welcome-message img {
    height: 20vh;
  }
}


@media (max-width: 1150px) {
  .welcome-message {
    width: 50vw;
    padding: 30px 60px;
  }

  .welcome-message img {
    height: 15vh;
  }
}

@media (max-width: 900px) {
  .welcome-message img {
    height: 11vh;
  }
}

@media (max-width: 750px) {
  .welcome-message {
    width: 50vw;
    padding: 30px 30px;
  }
}

@media (max-width: 700px) {
  .welcome-message {
    width: 50vw;
    padding: 30px 30px;
  }
}

@media (max-width: 650px) {
  .welcome-container {
    flex-direction: column;
    align-items: center;
  }

  .welcome-message {
    width: 90vw;
    padding: 30px 10px;
  }

  .welcome-message img {
    height: 20vh;
  }

  .welcome-social {
    width: 90vw;
  }
}

@media (max-width: 500px) {
  .welcome-message h1 {
    font-size: 2em;
  }

  .welcome-message img {
    height: 13vh;
  }

  .welcome-message p {
    font-size: 1em;
  }
}



/* MENU */
.menu-container {
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 2rem 2rem;
  /* background-color: #181818; */
}

.menu-menu {
  display: flex;
  flex-wrap: wrap;
  /* Compensate for excess margin on outer gallery flex items */
  margin: -1rem -1rem;
}


.menu-item {
  /* Minimum width of 24rem and grow to fit available space */
  flex: 1 0 20rem;
  /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
  margin: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

/* .menu-menu1 img {
  height: 100px;
  width: 100px;
}

.menu-menu2 img {
  height: 100px;
  width: 100px;
} */


/* PROMOTION */
.promotion-container {
  background-color: white;
  display: flex;
  flex-direction: column;
}

.promotion-image {
  align-self: center;
}

.promotion-image img {
  /* width: 60vw; */
  margin-bottom: 100px;
}

.promotion-blurb {
  font-family: 'Fontdiner Swanky', cursive;
  width: 100%;
  color: #181818;
  padding: 10px 30px;
}

.promotion-video {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
  color: #181818;
}

.promotion-video-card {
  text-align: center;
  margin-top: 30px;
}

.promotion-video-text {
  font-family: 'Josefin Slab', serif;
  text-align: center;
  margin: 10px;
}

.promotion-video a {
  font-size: 1.5em;
}

@media (max-width: 1350px) {
  .promotion-video video {
    width: 45vw;
  }
}

@media (max-width: 1000px) {
  .promotion-video video {
    width: 40vw;
  }
}

@media (max-width: 850px) {
  .promotion-image img {
    width: 95vw;
  }

  .promotion-container h1 {
    font-size: 1.7em;
  }
}

@media (max-width: 650px) {
  .promotion-video {
    flex-direction: column;
  }

  .promotion-video video {
    width: 80vw;
  }
}


/* BURGER */
.burger-container {
  /* height: 100vh;
  width: 100vw; */
  /* overflow-y: hidden; */
  background-color: #fffafa;
  color: #181818;
  text-align: center;
  font-family: 'Josefin Slab', serif;
  /* padding-top: 50px; */
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 2rem 2rem;
}

.burger-container h1 {
  margin: 50px 0;
}

.burger-gallery {
  background-color: #fffafa;
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr); */
  /* grid-gap: 1rem;
  grid-auto-flow: dense; */
  /* height: 100vh;
  width: 100vw; */
  display: flex;
  flex-wrap: wrap;
  /* Compensate for excess margin on outer gallery flex items */
  margin: -1rem -1rem;
}

.burger-gallery a {
  /* Minimum width of 24rem and grow to fit available space */
  flex: 1 0 24rem;
  /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
  margin: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.burger-gallery img {
  border: solid white;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 400ms ease-out;
}

.burger-gallery img:hover {
  transform: scale(1.15);
}


/* FOOTER */
.footer-container {
  margin-bottom: 20px;
}

.footer-container footer {
  background-color: white;
  color: #181818;
  display: flex;
  width: 100%;
}

.footer-legal {
  width: 70%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-legal p {
  margin: 0;
}

.footer-social {
  width: 30%;
}

.footer-social a {
  margin: 0 8px;
}

@media (max-width: 650px) {
  .footer-container footer {
    flex-direction: column-reverse;
    align-items: center;
  }

  .footer-social {
    display: flex;
    width: 80vw;
    justify-content: center;
    margin-bottom: 10px;
    ;
  }

  .footer-legal {
    width: 80vw;
  }
}



/* GETTOUS */
.gettous-container {
  background-color: #fffafa;
  color: #181818;
  height: 100%;
}

.gettous-header {
  display: flex;
  justify-content: center;
  padding-top: 2em;
}

.gettous-card {
  /* border: solid red; */
  display: flex;
  justify-content: space-evenly;
  padding: 2.5em;
  border-bottom: solid black;
  margin: 0 5em;
}

.gettous-card h1 {
  font-family: 'Fontdiner Swanky', cursive;
}

.gettous-map iframe {
  height: 20em;
  width: 35em;
}

.gettous-info {
  line-height: 3rem;
  font-family: 'Josefin Slab', serif;
}

.gettous-facebook {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1300px) {
  .gettous-map h1 {
    font-size: 2em;
  }

  .gettous-map iframe {
    height: 15em;
    width: 30em;
  }

  .gettous-info {
    font-size: 1em;
    line-height: 2rem;
  }
}

@media (max-width: 1200px) {
  .gettous-map h1 {
    font-size: 1.7em;
  }

  .gettous-map iframe {
    height: 15em;
    width: 28em;
  }

  .gettous-info {
    font-size: 1em;
    line-height: 1.5rem;
  }
}

@media (max-width: 1100px) {
  .gettous-card {
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }

  .gettous-map {
    text-align: center;
  }

  .gettous-map h1 {
    font-size: 1.7em;
  }

  .gettous-map iframe {
    height: 20em;
    width: 40em;
  }

  .gettous-info {
    font-size: 1em;
    line-height: 1.5rem;
    text-align: center;
    margin-top: 50px;
  }
}

@media (max-width: 800px) {
  .gettous-map iframe {
    height: 16em;
    width: 33em;
  }
}

@media (max-width: 600px) {
  .gettous-map h1 {
    font-size: 1.5em;
  }

  .gettous-map iframe {
    height: 14em;
    width: 29em;
  }

  .gettous-info {
    font-size: .9em;
    line-height: 1.3rem;
  }
}

@media (max-width: 550px) {
  .gettous-map h1 {
    font-size: 1.4em;
  }

  .gettous-map iframe {
    height: 15em;
    width: 25em;
  }
}

@media (max-width: 500px) {
  .gettous-map h1 {
    font-size: 1.2em;
  }

  .gettous-map iframe {
    height: 15em;
    width: 22em;
  }

  .gettous-info {
    font-size: .8em;
    line-height: 1.1rem;
  }
}

@media (max-width: 450px) {
  .gettous-map h1 {
    font-size: 1.1em;
  }

  .gettous-map iframe {
    height: 13em;
    width: 20em;
  }

  .gettous-info {
    font-size: .8em;
    line-height: 1.1rem;
  }
}

@media (max-width: 400px) {
  .gettous-map h1 {
    font-size: 1.1em;
  }

  .gettous-map iframe {
    height: 13em;
    width: 17em;
  }

  .gettous-info {
    font-size: .7em;
    line-height: 1rem;
  }
}